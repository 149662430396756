import Keycloak from 'keycloak-js'
import Queue from '../../utils/Queue'
import UserService from '../user'
import logger from '../logger'
import { eventBus } from '@je-pc/utils'

import {
  IS_PRODUCTION,
  SMARTGATEWAY_URL,
  TENANT,
  KEYCLOAK_CONFIG,
  KEYCLOAK_IMPERSONATION_CONFIG,
  PARTNER_USER_TYPE,
  IMPERSONATED_USER_TYPE,
  KEYCLOAK_API_HOST,
} from '../../constants'
import { tokenTypesConfig } from '../../utils/tokenTypeConfig'
import {
  setKeycloakData,
  getKeycloakDataByType,
  clearKeycloakData,
} from '../../utils/keycloakDataStorage'
import {
  setImpersonatedUserAuthInProgress,
  removeImpersonatedUserAuthInProgress,
  isImpersonatedUserAuthenticationFlow,
  getShouldSkipChooseBusiness,
} from '../../utils/authentication-flow'
import {
  hasImpersonationParams,
  appendImpersonationParams,
  getImpersonationParams,
} from '../../utils/impersonation'
import { fetchWithToken } from '../../utils/fetchWithToken'

const ACCEPT_TERMS_API =
  '/user-management/api/v1/user-management/users/me/accept-terms'
const TENANT_LOCALE = {
  uk: 'en',
  ie: 'en',
  es: 'es',
  it: 'it',
  au: 'en',
  nz: 'en',
}

const CLIENT_USER_TYPE = {
  [KEYCLOAK_CONFIG.clientId]: PARTNER_USER_TYPE,
  [KEYCLOAK_IMPERSONATION_CONFIG.clientId]: IMPERSONATED_USER_TYPE,
}

class KeycloakAuthService {
  constructor(config) {
    this.exchangedToken = null
    this.config = config
    this.keycloak = new Keycloak(this.config)

    this.keycloak.onAuthSuccess = () => {
      removeImpersonatedUserAuthInProgress()
    }

    this.keycloak.onAuthError = (e) => {
      removeImpersonatedUserAuthInProgress()
      logger.error(new Error('Authentication Error'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
      })
    }

    this.keycloak.onAuthRefreshError = () => {
      this.clearToken()
    }

    this.keycloak.onAuthLogout = () => {
      this.clearToken()
      this.onAuthLogoutHandler()
    }

    this.updateCreateLoginUrlFunction()

    Queue.enqueue(this.init.bind(this))
  }

  setGlobalAuthService(globalAuthService) {
    this.globalAuthService = globalAuthService
  }

  onAuthLogoutHandler() {}

  updateCreateLoginUrlFunction() {
    const originalCreateLoginUrl = this.keycloak.createLoginUrl
    const restaurantId =
      getImpersonationParams().restaurantId || UserService.getRestaurantId()

    this.keycloak.createLoginUrl = function (options) {
      const url = originalCreateLoginUrl.call(this, options)

      if (!restaurantId) {
        removeImpersonatedUserAuthInProgress()
        return url
      }

      if (this.clientId === KEYCLOAK_IMPERSONATION_CONFIG.clientId) {
        setImpersonatedUserAuthInProgress()
        return appendImpersonationParams(url, restaurantId)
      }

      return url
    }
  }

  async init() {
    try {
      if (hasImpersonationParams()) this.clearToken()
      const userType = CLIENT_USER_TYPE[this.config.clientId]
      const { token, refreshToken, idToken, timeSkew } =
        getKeycloakDataByType(userType)

      const authenticated = await this.keycloak.init({
        onLoad: isImpersonatedUserAuthenticationFlow()
          ? 'login-required'
          : 'check-sso',
        token,
        refreshToken,
        idToken,
        timeSkew,
        responseMode: 'query',
        enableLogging: !IS_PRODUCTION,
        pkceMethod: 'S256',
        checkLoginIframe: false,
      })

      if (authenticated) {
        setKeycloakData(this.keycloak)
      }
    } catch (e) {
      const message = e?.message || e?.error
      eventBus.emit('auth-error', { type: 'keycloackInit', level: 'critical' })
      logger.error(new Error('Keycloak Init error'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
      })
      throw new Error(`Keycloak failed to initialize: ${message}`)
    }
  }

  async exchangeToken() {
    try {
      const restaurantId = UserService.getRestaurantId()
      if (!restaurantId) return
      this.exchangedToken = await this.fetchExchangedToken(restaurantId)
      return this.exchangedToken
    } catch (e) {
      eventBus.emit('auth-error', { type: 'exchangeToken', level: 'critical' })
      logger.error(new Error('Exchange Token Error'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
      })
    }
  }

  async fetchRestaurants(restaurantId) {
    try {
      const token = await this.getValidKeycloakToken()
      const response = await fetch(
        `${SMARTGATEWAY_URL}/restaurants/${TENANT}/${restaurantId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        return await response.json()
      }

      logger.error(
        new Error(
          `Failed to fetchRestaurant ${restaurantId}. Server responded with ${response.status} - ${response.statusText}`
        ),
        {
          response: response,
        }
      )
      return null
    } catch (e) {
      logger.error(new Error(`Failed to fetchRestaurant`), {
        originalError: e,
      })
      return null
    }
  }

  async fetchCurrentUser() {
    try {
      const userData = await Queue.enqueue(
        this.getValidUserInfoAndRestaurantId.bind(this)
      )

      if (!userData || !userData.userInfo) return null
      const { userInfo, restaurantId } = userData

      const currentUser = this.mapCurrentUser(userInfo)

      if (restaurantId) {
        const restaurantData = await this.fetchRestaurants(restaurantId)

        if (restaurantData) {
          const restaurant = this.mapCurrentUserRestaurant(restaurantData)
          currentUser.restaurant = { ...currentUser.restaurant, ...restaurant }
        }
      }

      return currentUser
    } catch (e) {
      logger.error(new Error('Fetch Current User'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
      })
    }
  }

  mapCurrentUser(userInfo) {
    return {
      authTime: Date.now() / 1000,
      nameIdentifier: userInfo.aid,
      name: userInfo.preferred_username || userInfo.email,
      isJustEatUser: this.isImpersonatedUser(),
      isJustEatEditUser: this.isImpersonatedUser(),
      roles: [],
      hideSubnavigation: false,
      restaurant: {
        restaurantCount: String(this.getUserRestaurantIds(userInfo).length),
      },
    }
  }

  mapCurrentUserRestaurant(restaurantData) {
    return {
      id: restaurantData?.id,
      name: restaurantData?.name,
      logoUrl: restaurantData?.logoUrl,
      bannerUrl: '',
      mobileNumber:
        restaurantData?.contactOptions?.phoneNumbers[0]?.phoneNumber ?? '',
      invoiceEmail: restaurantData?.contactOptions?.emails[0]?.email ?? '',
      description: `${restaurantData?.location?.streetAdress}, ${restaurantData?.location?.postalCode}`,
    }
  }

  async fetchExchangedToken(restaurantId) {
    const url = `${this.config.url}/realms/${this.config.realm}/protocol/openid-connect/token`
    const subject_token = await this.getValidKeycloakToken()
    const headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': restaurantId,
    })
    const body = new URLSearchParams({
      client_id: this.config.clientId,
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      subject_token,
    })

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
      credentials: 'include',
    })

    if (!response.ok) {
      throw new Error(
        `Failed to exchange token by keycloak. Server responded with ${response.status} - ${response.statusText}`
      )
    }
    const { access_token } = await response.json()
    return access_token
  }

  async acceptTermsAndConditions() {
    const token = await this.getValidKeycloakToken()
    await fetchWithToken({
      host: KEYCLOAK_API_HOST,
      url: ACCEPT_TERMS_API,
      token,
      params: {
        method: 'PUT',
      },
    })
  }

  logout(params = {}) {
    const defaultParams = {
      isChangingRestaurant: false,
      redirectUri: location.origin,
    }
    params = { ...defaultParams, ...params }
    if (params.isChangingRestaurant) {
      UserService.redirectUserToRestaurantSelectPage()
    } else {
      this.keycloak.logout({ redirectUri: params.redirectUri })
    }
  }

  checkAuth(params) {
    return Queue.enqueue(this.checkAuthHandler.bind(this, params))
  }

  clearCheckAuthQueue() {
    return Queue.clear()
  }

  async checkAuthHandler(params = {}) {
    try {
      const defaultParams = {
        forceRefresh: false,
        tokenType: tokenTypesConfig.default,
      }

      params = { ...defaultParams, ...params }

      const minValidity = params.forceRefresh ? -1 : 5
      const refreshed = await this.updateTokens(minValidity)

      if (refreshed) {
        await this.getValidUserInfoAndRestaurantId()
        setKeycloakData(this.keycloak)
      }

      if (
        params.tokenType === tokenTypesConfig.list.keycloak ||
        this.isImpersonatedUser()
      ) {
        return await this.getValidKeycloakToken()
      }

      if (
        params.tokenType === tokenTypesConfig.list.connect ||
        params.tokenType === tokenTypesConfig.list.exchanged
      ) {
        if (params.forceRefresh || !this.exchangedToken)
          await this.exchangeToken()
        return this.exchangedToken
      }

      logger.error(
        `Token type ${
          params.tokenType
        } is not supported. Supported token types are: ${Object.values(
          tokenTypesConfig.list
        )}`
      )
    } catch (e) {
      this.clearCheckAuthQueue()
      throw new Error('Unauthorized', { cause: e })
    }
  }

  async getValidUserInfoAndRestaurantId() {
    try {
      if (!this.keycloak.authenticated) return null

      const userInfo = await this.loadUserInfo()

      if (!userInfo) {
        logger.error(new Error('User info empty'), {
          restaurantId: UserService.getRestaurantId(),
        })
      }
      const shouldSkipChooseBusiness = getShouldSkipChooseBusiness()
      const isTCAccepted = userInfo?.tca
      const userRestaurantIds = this.getUserRestaurantIds(userInfo)
      const restaurantId = this.getSelectedRestaurantId(userRestaurantIds)

      if (!isTCAccepted && !this.isImpersonatedUser()) {
        UserService.redirectToTermsAndConditionsPage()
      } else if (!restaurantId && !shouldSkipChooseBusiness) {
        UserService.redirectUserToRestaurantSelectPage()
      }

      return { userInfo, restaurantId }
    } catch (e) {
      eventBus.emit('auth-error', {
        type: 'getValidUserInfoAndRestaurantId',
        level: 'critical',
      })
      logger.error(new Error('Get valid user info and restaurant id'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
      })
    }
  }

  async loadUserInfo() {
    try {
      return await this.keycloak.loadUserInfo()
    } catch (e) {
      logger.error(new Error('Keycloak Load User Info error'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
        authenticated: this.keycloak.authenticated,
      })
      if (this.keycloak.authenticated) await this.keycloak.logout()
      return null
    }
  }

  getSelectedRestaurantId(userRestaurantIds) {
    try {
      const selectedRestaurantId = UserService.getRestaurantId()
      if (
        selectedRestaurantId &&
        userRestaurantIds.includes(selectedRestaurantId)
      )
        return selectedRestaurantId
      if (userRestaurantIds.length === 1) {
        const restaurantId = userRestaurantIds[0]
        this.globalAuthService.selectUserRestaurant(restaurantId)
        return UserService.getRestaurantId()
      }

      return null
    } catch (e) {
      eventBus.emit('auth-error', {
        type: 'getSelectedRestaurantId',
        level: 'critical',
      })
      logger.error(new Error('Get selected restaurant id'), {
        originalError: e,
        restaurantId: UserService.getRestaurantId(),
      })
    }
  }

  async authenticate() {
    if (this.keycloak.authenticated) return true

    await this.keycloak.login({
      redirectUri: window.location.href,
      locale: TENANT_LOCALE[TENANT],
    })

    return false
  }

  isAuthorized() {
    return this.keycloak.authenticated
  }

  isImpersonatedUser() {
    return this.keycloak.tokenParsed?.atyp === IMPERSONATED_USER_TYPE
  }

  getUserRestaurantIds(userInfo) {
    return userInfo.rids ?? this.keycloak.tokenParsed.rids ?? []
  }

  /**
   * Generates url which user can use to activate Multi-factor authentication
   * @param {Object} params - Additional parameters.
   * @param {String} [params.redirectUri=location.href] - Url where user is redirected after MFA activation
   * @return {String} Generated url
   */
  createMfaActivationUrl(params) {
    const defaultParams = { redirectUri: location.href }
    params = { ...defaultParams, ...params }

    const url = this.keycloak.createLoginUrl({
      redirectUri: params.redirectUri,
      locale: TENANT_LOCALE[TENANT],
      action: 'CONFIGURE_TOTP_JET',
    })

    return url
  }

  /**
   * Gets valid token.
   * If token is failed to be fetched due to any reason returns `undefined`.
   * @param {Object} params - Additional parameters.
   * @param {boolean} [params.forceRefresh=false] - Defines whether token is forced to be refreshed via APi call.
   * @param {boolean} [params.forceAuthentication=true] - Defines whether user is forced to authenticate if valid token failed to be retrieved. For authentication user is redirected to login page.
   * @param {('exchanged'|'keycloak'} [params.tokenType='exchanged'] - Defines type of token to return: original keycloak token or exchanged token.
   * @return {Object|undefined} Valid token.
   */
  async getValidToken(params = {}) {
    try {
      const defaultParams = {
        forceRefresh: false,
        forceAuthentication: true,
        tokenType: tokenTypesConfig.default,
      }

      params = { ...defaultParams, ...params }
      const validToken = await this.checkAuth({
        forceRefresh: params.forceRefresh,
        tokenType: params.tokenType,
      })
      return validToken
    } catch {
      if (params.forceAuthentication) {
        this.authenticate()
      }
    }
  }

  async updateTokens(minValidity = 5) {
    const refreshed = await this.keycloak.updateToken(minValidity)
    if (refreshed && this.exchangedToken) {
      await this.exchangeToken()
    }
    return refreshed
  }

  async getValidKeycloakToken() {
    try {
      const refreshed = await this.updateTokens(5)
      if (refreshed) {
        setKeycloakData(this.keycloak)
      }
      return this.keycloak.token
    } catch {
      this.authenticate()
    }
  }

  clearToken() {
    clearKeycloakData()
    this.exchangedToken = null
  }
}

export default KeycloakAuthService
